@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900;1000&display=swap');

body{
    background-color: white;
}

textarea{
    background-attachment: local;
    background: transparent;
    background-image:
        /* linear-gradient(to right, white 10px, transparent 10px), */
        /* linear-gradient(to left, white 10px, transparent 10px), */
        repeating-linear-gradient(transparent, transparent 30px, #809FC6 30px, #809FC6 31px, #809FC6 31px);
    line-height: 30px;
    padding: 4px 2px;
    /* text-light */ 
    color: #809FC6;
    resize: none;
}

h3 {
    color: white; /* Unfortunately you can't use transparent here … */
    text-shadow:
	   -1px -1px 0 #000,  
		1px -1px 0 #000,
		-1px 1px 0 #000,
		 1px 1px 0 #000;

    font-size: 40px;
}
    /* Real outline for modern browsers */
@supports((text-stroke: 1.5px #809FC6) or (-webkit-text-stroke: 1.5px #809FC6)) {
    h3 {
        color: transparent;
        -webkit-text-stroke: 1px #809FC6;
		stroke: 1px #809FC6;
        text-shadow: none;
    }
}

h4 {
    color: white; /* Unfortunately you can't use transparent here … */
    text-shadow:
	   -1px -1px 0 #000,  
		1px -1px 0 #000,
		-1px 1px 0 #000,
		 1px 1px 0 #000;

    font-size: 40px;
}
    /* Real outline for modern browsers */
@supports((text-stroke: 1.5px #091E38) or (-webkit-text-stroke: 1.5px #091E38)) {
    h4 {
        color: transparent;
        -webkit-text-stroke: 1px #091E38;
		stroke: 1px #091E38;
        text-shadow: none;
    }
}

    /* Real outline for modern browsers */
@supports((text-stroke: 1.5px white) or (-webkit-text-stroke: 1.5px white)) {
    h5 {
        color: transparent;
        -webkit-text-stroke: 1px white;
		stroke: 1px white;
        text-shadow: none;
    }
}
